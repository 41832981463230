import { CircularProgress } from "components";
import React, { FC, Fragment } from "react";
import { LoaderContent, LoaderStyled } from "./Loader.style";

const Loader: FC<{ fullWidth?: boolean }> = ({ fullWidth = false }) => {
  return (
    // <LoaderContent fullWidth={fullWidth}>
    //   <div className="loader-page">qweqwe</div>
    // </LoaderContent>
    <LoaderStyled fullWidth={fullWidth}>
      <CircularProgress size={25} thickness={4} />
    </LoaderStyled>
  );
};

export default Loader;
