import styled from "styled-components";
import { Button } from "@mui/material";
import { theme } from "styles/theme/theme";

export const SaveButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.colors.button.blue} !important;
  padding: 12px 32px !important;
  border-radius: 24px !important;
  color: ${({ theme }) => theme.colors.text.white} !important;
  text-transform: capitalize !important ;
  opacity: ${({ theme, disabled }) => (disabled ? 0.6 : 1)};
`;
