import React, { FC, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { firstErrorField } from "../../../utils/firstErrorField";
import ErrorMessage from "../../common/ErrorMessage/ErrorMessage";
import Label from "../Label/Label";
import { InputContentStyled } from "./Input.style";
import { IInputProps } from "./Input.types";

const Input: FC<IInputProps> = ({
  control,
  name = "",
  rules = { required: false, max: 100 },
  id,
  label = "",
  className = "",
  errors,
  FORM_NAMES,
  disabled = false,
  onChange,
  placeholder = "",
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const focus = async () => {
    if (inputRef.current !== null) {
      console.log(inputRef?.current);
      if (errors) {
        if (!!errors[name]) {
          let key = firstErrorField(FORM_NAMES, errors);

          if (key === name) {
            inputRef?.current?.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    focus();
  }, [errors && errors[name]]);

  return (
    <InputContentStyled
      className={className}
      isError={!!errors && !!errors[name]}
    >
      {label && <Label value={label} />}

      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { ...field } }) => (
          <input
            {...field}
            {...props}
            value={field.value || ""}
            onWheel={(e) => e.currentTarget.blur()}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              onChange?.(e);
            }}
          />
        )}
      />

      {errors && errors[name] && <ErrorMessage value={errors[name]?.message} />}
    </InputContentStyled>
  );
};

export default Input;
