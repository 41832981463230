import React from "react";
import { StyledCustomSwitch } from "./CustomSwitch";

const Index = (props: any) => {
  return (
    <StyledCustomSwitch>
      <div className="centering">
        <label className="switch">
          <input type="checkbox" {...props} />
          <span className="slider"></span>
        </label>
      </div>
    </StyledCustomSwitch>
  );
};

export default Index;
