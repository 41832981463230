import { useEffect } from "react";
import { REQUEST_STATUS, useRequest } from "hooks";
import get from "lodash.get";
import { useNavigate } from "react-router-dom";

import browserStorage from "services/storage/browserStorage";
import { IUseLogin } from "./LoginContext.types";

export const useLogin = (): IUseLogin => {
  const navigate = useNavigate();
  const [loginClient, loginData, loginStatus, loginError] = useRequest();
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();

  const loginFunction = async (loginQuery: any) => {
    await loginClient.post("/sign-in", loginQuery);
  };
  const getRole = async (id: string) => {
    // await getRoleClient.get(`employee/profile`);
  };

  // const updateWindow = async () => {
  //   if (loginStatus === REQUEST_STATUS.success) {
  //     browserStorage.set("token", get(loginData, "data.token"));
  //     browserStorage.set("admin", get(loginData, "data.admin"));
  //     // getRole(get(loginData, "data.admin")?._id);
  //     // await navigate("/");
  //     // await window.location.reload();
  //   }
  // };
  const role = async () => {
    if (getRoleStatus === REQUEST_STATUS.success) {
      browserStorage.set("role", get(getRoleData, "data.role"));
      await navigate("/");
      // let a: any = await SidebarList.filter((item: any) => {
      //   if (get(getRoleData, "data")[item.id]) return item;
      // });
      // await navigate(`${a[0].path}`);
    }
  };

  useEffect(() => {
    if (loginStatus === REQUEST_STATUS.success) {
      browserStorage.set("token", loginData.data.token);
      browserStorage.set("admin", loginData.data);
      navigate("/users");
    }
  }, [loginStatus]);
  useEffect(() => {
    role();
  }, [getRoleStatus]);

  return {
    state: {
      loginState: { loginData, loginStatus, loginError },
      getRoleState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: { loginFunction },
  };
};
