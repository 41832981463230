import { ILanguageT } from "./language.type";

export const language: ILanguageT[] = [
  {
    name: "O'zbekcha",
    value: "uz",
  },
  {
    name: "Русский",
    value: "ru",
  },
  {
    name: "English",
    value: "en",
  },
];

export const DEFAULT_LANGUAGE = "uz";
