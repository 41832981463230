import React from "react";

import LoginContext from "./LoginContext";
import { useLogin } from "./useLogin";

const LoginProvider = ({ children }: any) => {
  const value = useLogin();

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};

export default LoginProvider;
