import { Route, Routes as Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Toaster } from "react-hot-toast";
import CommonProvider from "context/CommonProvider";
import Dashboard from "routes/container/Routes";
import Login from "pages/login/container";
import { LightTheme } from "styles/theme/lightTheme";
import "styles/index.scss";
import { Suspense } from "react";
import Loader from "pages/loader/container/Loader";

import "react-quill/dist/quill.snow.css";

function App() {
  return (
    <CommonProvider>
      <ThemeProvider theme={LightTheme}>
        <Suspense fallback={<Loader fullWidth />}>
          <Switch>
            <Route path="*" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
          </Switch>
        </Suspense>
      </ThemeProvider>

      <Toaster containerStyle={{ zIndex: "100000" }} />
    </CommonProvider>
  );
}

export default App;
