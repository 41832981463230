import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { LoginPageStyle } from "./Login.styled";
import AuthImage from "../assets/images/authImage.png";
import EduSystemIcon from "../assets/icons/EduSystemIcon";
import LogoTitle from "../assets/icons/LogoTitle";
import { Input, PhoneInput } from "components";
import { LOGIN_FORM_NAMES } from "./Login.constants";
import { phoneNumberPattern } from "constants/pattern";
import LoginButton from "../components/LoginButton/LoginButton";
import { useContext } from "react";
import { LoginContext } from "../context";

const Login = () => {
  const {
    state: {},
    actions: { loginFunction },
  } = useContext(LoginContext);
  const { handleSubmit, register, formState, control, setValue } = useForm();
  const { t } = useTranslation();
  const submit = (data: any) => {
    const newData = {
      ...data,
      [LOGIN_FORM_NAMES.phoneNumber]: data[LOGIN_FORM_NAMES.phoneNumber]
        .split(" ")
        .join(""),
    };
    loginFunction(newData);
  };
  return (
    <LoginPageStyle>
      <div className="left-content">
        <img src={AuthImage} alt="Auth image" />
      </div>
      <div className="right-content">
        <div className="mx-auto w-75 h-100">
          <div className="d-flex justify-content-between pt-4">
            <div className="d-flex align-items-center">
              <EduSystemIcon />
              <div
                className="h-75 mx-2"
                style={{ border: "0.2px solid #000", opacity: "0.1" }}
              />
              <div>
                <LogoTitle />
                <p className="mb-0">bilan yanada onsonroq</p>
              </div>
            </div>
            {/* {browserStorage.get("i18nextLng") === "uz" ? (
              <UzbekistanFlag style={{ cursor: "pointer" }} />
            ) : browserStorage.get("i18nextLng") === "ru" ? (
              <RussianFlag style={{ cursor: "pointer" }} />
            ) : (
              <UnitedStates style={{ cursor: "pointer" }} />
            )} */}
          </div>

          <form action="" onSubmit={handleSubmit(submit)}>
            <div className="title">Kirish</div>
            <PhoneInput
              control={control}
              name={LOGIN_FORM_NAMES.phoneNumber}
              label={"Telefon raqam"}
              rules={{
                required: {
                  value: true,
                  message: "Telefon raqam kiritish majburiy",
                },
              }}
              FORM_NAMES={LOGIN_FORM_NAMES}
              errors={formState.errors}
            />

            <Input
              control={control}
              name={LOGIN_FORM_NAMES.password}
              label="Password"
              rules={{
                required: {
                  value: true,
                  message: "Password kiritish majburiy",
                },
              }}
              FORM_NAMES={LOGIN_FORM_NAMES}
              errors={formState.errors}
              type={"password"}
              className="mt-4"
            />
            <LoginButton type="submit" value="Login" className="mt-3" />
          </form>
        </div>
      </div>
    </LoginPageStyle>
  );
};

export default Login;
