import styled from "styled-components";

export const LoginPageStyle = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;

  .MuiGrid-root.MuiGrid-container {
    height: 100%;
    .loginPageRight {
      background: ${({ theme }) => theme.colors.bg.mainLightBg};
    }
    .loginPageLeft {
      padding: 0 100px;
      padding-top: 30px;
      padding-bottom: 100px;
      svg {
        margin-bottom: 170px;
      }
    }
  }

  .left-content {
    width: 60%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right-content {
    width: 40%;
    height: 100%;
    position: relative;
    form {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 80%;
      margin: 0 auto;
      .title {
        font-size: 40px;
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }
`;
