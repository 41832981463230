import styled from "styled-components";

export const StyledCustomSwitch = styled.div`
  .centering {
    display: flex;
    align-items: center;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 37px;
    height: 15px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dfe3f6;
    border-radius: 40px;
    -webkit-transition: 0.4s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 23px;
    background: #f5f5f5;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: -4px;
    right: auto;
    -webkit-transition: 0.3s;
    transition: all 0.3s;
  }

  input:checked + .slider {
    background: #e9e9e9;
  }

  input:checked + .slider:before {
    -webkit-transform: translate(10px, -50%);
    -moz-transform: translate(10px, -50%);
    transform: translate(2px, -50%);
    background: #3d68ff;
    right: -4px;
    left: auto;
    top: 50%;
    transition: all 0.3s;
  }

  input:focus + .slider {
  }
`;
