import React from "react";

const Logo = (props: any) => {
  return (
    <>
      <svg
        width="160"
        height="41"
        viewBox="0 0 160 41"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_5711_29265)">
          <path
            d="M52.0584 27.0223H38.5625V7.65625H52.0847V11.6919H42.9124V15.2821H51.4033V19.2914H42.9124V22.9866H52.0577L52.0584 27.0223Z"
            fill="white"
          />
          <path
            d="M65.2653 14.0577V7.08594H69.3797V27.0288H65.3967V25.483C64.2781 26.7583 62.8547 27.3959 61.1265 27.3957C59.1169 27.3957 57.431 26.6793 56.0688 25.2467C54.723 23.797 54.0503 21.9975 54.0508 19.8485C54.0508 17.7172 54.7235 15.9261 56.0688 14.4751C57.4315 13.0252 59.1174 12.3002 61.1265 12.3002C62.7686 12.3009 64.1482 12.8867 65.2653 14.0577ZM65.4753 19.8225C65.4753 18.7571 65.1259 17.8661 64.427 17.1497C63.7456 16.4169 62.872 16.0505 61.8061 16.0505C60.7403 16.0505 59.858 16.4174 59.1593 17.1511C58.4778 17.8849 58.1371 18.7758 58.1373 19.8239C58.1373 20.9076 58.478 21.8161 59.1593 22.5493C59.858 23.2831 60.7403 23.65 61.8061 23.65C62.872 23.65 63.7456 23.2831 64.427 22.5493C65.1261 21.8322 65.4757 20.9232 65.4757 19.8225H65.4753Z"
            fill="white"
          />
          <path
            d="M79.102 27.3898C77.0058 27.3898 75.3593 26.8133 74.1624 25.6604C72.9656 24.5074 72.3672 22.9088 72.3672 20.8646V12.6641H76.4815V20.8909C76.4815 21.7295 76.7131 22.3938 77.1761 22.8837C77.6392 23.3736 78.2812 23.6182 79.102 23.6174C79.9233 23.6174 80.5654 23.3729 81.0282 22.8837C81.491 22.3945 81.7225 21.7303 81.7225 20.8909V12.6641H85.8371V20.8664C85.8371 22.9455 85.2431 24.5529 84.055 25.6885C82.867 26.8241 81.216 27.3912 79.102 27.3898Z"
            fill="white"
          />
          <path
            d="M104.705 22.9348L105.858 26.2107C104.635 26.9967 103.168 27.3897 101.456 27.3897C98.031 27.3897 96.3187 25.4592 96.319 21.5982V16.0687H94.0391V12.6639H96.3453V7.57812H100.434V12.6639H105.046V16.0705H100.434V21.2043C100.434 22.8291 101.071 23.6414 102.346 23.6411C103.01 23.6419 103.797 23.4064 104.705 22.9348Z"
            fill="white"
          />
          <path
            d="M111.703 12.6641V27.0229H107.59V12.6641H111.703Z"
            fill="white"
          />
          <path
            d="M126.431 15.5535L119.748 23.6249H126.719V27.0314H114.086V24.1736L120.926 16.1022H114.402V12.6953H126.43L126.431 15.5535Z"
            fill="white"
          />
          <path
            d="M133.376 12.6641V27.0229H129.262V12.6641H133.376Z"
            fill="white"
          />
          <path
            d="M154.104 12.2734C155.799 12.2734 157.205 12.7976 158.324 13.8459C159.442 14.8943 160.001 16.3792 160.001 18.3008V27.0273H155.886V19.0869C155.886 18.1085 155.646 17.3573 155.166 16.8333C154.685 16.3092 154.043 16.0478 153.24 16.049C152.366 16.049 151.658 16.3372 151.117 16.9137C150.576 17.4902 150.305 18.2588 150.304 19.2196V27.0273H146.19V19.1132C146.19 18.17 145.941 17.4227 145.443 16.8714C144.945 16.32 144.294 16.0459 143.491 16.049C142.634 16.049 141.936 16.3372 141.394 16.9137C140.853 17.4902 140.582 18.2676 140.582 19.2459V27.0273H136.469V12.6684H140.478V14.7124C140.88 13.9438 141.483 13.3411 142.287 12.9044C143.09 12.4676 143.964 12.2579 144.907 12.2752C145.815 12.2752 146.636 12.4805 147.371 12.8912C148.105 13.3018 148.655 13.9089 149.021 14.7124C149.545 13.9438 150.262 13.3454 151.17 12.9172C152.079 12.489 153.057 12.2744 154.104 12.2734Z"
            fill="white"
          />
          <path
            d="M107.067 7.57812H110.655C110.883 7.57812 111.108 7.62301 111.319 7.71023C111.529 7.79745 111.721 7.92528 111.882 8.08643C112.043 8.24759 112.171 8.4389 112.258 8.64946C112.345 8.86002 112.39 9.08569 112.39 9.3136V9.58511C112.39 9.81302 112.345 10.0387 112.258 10.2493C112.171 10.4598 112.043 10.6511 111.882 10.8123C111.721 10.9734 111.529 11.1013 111.319 11.1885C111.108 11.2757 110.883 11.3206 110.655 11.3206H108.802C108.342 11.3206 107.9 11.1377 107.575 10.8123C107.249 10.4868 107.066 10.0454 107.066 9.58511V7.57812H107.067Z"
            fill="white"
          />
          <path
            d="M128.657 7.57812H132.245C132.705 7.57813 133.146 7.76097 133.472 8.08643C133.797 8.4119 133.98 8.85332 133.98 9.3136V9.58511C133.98 10.0454 133.797 10.4868 133.472 10.8123C133.146 11.1377 132.705 11.3206 132.245 11.3206H130.392C129.931 11.3206 129.49 11.1377 129.165 10.8123C128.839 10.4868 128.656 10.0454 128.656 9.58511V7.57812H128.657Z"
            fill="white"
          />
          <path
            d="M2.67266 7.80221L25.5231 -0.00342811C26.1999 1.97783 26.0619 4.14679 25.1395 6.02631C24.2171 7.90583 22.5858 9.34195 20.6046 10.0187L5.2245 15.2725C4.23387 15.6109 3.14939 15.5419 2.20963 15.0807C1.26987 14.6195 0.551814 13.8039 0.213419 12.8133C-0.124976 11.8227 -0.0559859 10.7382 0.405212 9.79842C0.866409 8.85866 1.68204 8.1406 2.67266 7.80221Z"
            fill="white"
          />
          <path
            d="M2.71954 32.3803L25.57 24.5747C25.9052 25.5557 26.0439 26.5932 25.9781 27.6279C25.9124 28.6625 25.6435 29.6741 25.1868 30.6048C24.7301 31.5355 24.0945 32.3671 23.3164 33.0522C22.5382 33.7373 21.6328 34.2624 20.6517 34.5975L5.27161 39.8513C4.78104 40.0189 4.26227 40.0882 3.74492 40.0553C3.22757 40.0223 2.72177 39.8878 2.25642 39.6594C1.79106 39.431 1.37526 39.1131 1.03277 38.7239C0.690269 38.3348 0.427781 37.882 0.260294 37.3914C-0.0781007 36.4008 -0.0091112 35.3163 0.452086 34.3765C0.913284 33.4368 1.72891 32.7187 2.71954 32.3803Z"
            fill="white"
          />
          <path
            d="M16.5913 15.4436L2.43593 20.2791C0.600883 20.9059 -0.378559 22.9017 0.248288 24.7367L0.530113 25.5617C1.15696 27.3968 3.15272 28.3762 4.98777 27.7494L19.1431 22.914C20.9782 22.2871 21.9576 20.2913 21.3308 18.4563L21.0489 17.6313C20.4221 15.7962 18.4263 14.8168 16.5913 15.4436Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_5711_29265">
            <rect width="160" height="40.0604" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Logo;
