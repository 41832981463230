import { Button } from "@mui/material";
import styled from "styled-components";

export const CancelButtonStyled = styled(Button)`
  border-color: ${({ theme }) => theme.colors.button.blue} !important;
  padding: 12px 32px !important;
  border-radius: 24px !important;
  color: ${({ theme }) => theme.colors.button.blue} !important;
  background-color: transparent !important;
  text-transform: capitalize !important ;
`;
