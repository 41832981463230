import React from 'react';

const LogoTitle = () => {
    return (
        <>
            <svg width="122" height="21" viewBox="0 0 122 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.4959 19.9441H0V0.578125H13.5222V4.61381H4.34989V8.20397H12.8408V12.2133H4.34989V15.9085H13.4952L13.4959 19.9441Z" fill="#3D68FF"/>
                <path d="M26.7028 6.97179V0H30.8172V19.9428H26.8342V18.397C25.7156 19.6724 24.2922 20.31 22.564 20.3097C20.5544 20.3097 18.8685 19.5934 17.5063 18.1607C16.1605 16.711 15.4878 14.9116 15.4883 12.7625C15.4883 10.6312 16.161 8.84012 17.5063 7.3892C18.869 5.93924 20.5549 5.21425 22.564 5.21425C24.2061 5.21496 25.5857 5.80081 26.7028 6.97179ZM26.9128 12.7365C26.9128 11.6711 26.5634 10.7802 25.8645 10.0638C25.1831 9.33095 24.3095 8.96454 23.2436 8.96454C22.1778 8.96454 21.2955 9.33142 20.5968 10.0652C19.9153 10.7989 19.5746 11.6899 19.5748 12.738C19.5748 13.8216 19.9155 14.7301 20.5968 15.4634C21.2955 16.1972 22.1778 16.5641 23.2436 16.5641C24.3095 16.5641 25.1831 16.1972 25.8645 15.4634C26.5636 14.7463 26.9132 13.8373 26.9132 12.7365H26.9128Z" fill="#3D68FF"/>
                <path d="M40.5395 20.3117C38.4433 20.3117 36.7968 19.7352 35.5999 18.5822C34.4031 17.4293 33.8047 15.8307 33.8047 13.7865V5.58594H37.919V13.8128C37.919 14.6514 38.1506 15.3157 38.6136 15.8056C39.0767 16.2954 39.7187 16.54 40.5395 16.5393C41.3608 16.5393 42.0029 16.2947 42.4657 15.8056C42.9285 15.3164 43.16 14.6521 43.16 13.8128V5.58594H47.2746V13.7883C47.2746 15.8674 46.6806 17.4747 45.4925 18.6104C44.3045 19.746 42.6535 20.3131 40.5395 20.3117Z" fill="#3D68FF"/>
                <path d="M66.1428 15.8567L67.2957 19.1326C66.0726 19.9186 64.605 20.3115 62.8932 20.3115C59.4685 20.3115 57.7562 18.3811 57.7565 14.5201V8.99055H55.4766V5.58579H57.7828V0.5H61.8712V5.58579H66.4837V8.99233H61.8712V14.1262C61.8712 15.751 62.5087 16.5633 63.7838 16.563C64.4479 16.5637 65.2342 16.3283 66.1428 15.8567Z" fill="#3D68FF"/>
                <path d="M73.1402 5.58594V19.9448H69.0273V5.58594H73.1402Z" fill="#3D68FF"/>
                <path d="M87.8686 8.46755L81.1857 16.5389H88.1561V19.9455H75.5234V17.0876L82.3632 9.01627H75.8398V5.60938H87.8675L87.8686 8.46755Z" fill="#3D68FF"/>
                <path d="M94.8136 5.58594V19.9448H90.6992V5.58594H94.8136Z" fill="#3D68FF"/>
                <path d="M115.542 5.1875C117.236 5.1875 118.643 5.71167 119.761 6.76C120.879 7.80833 121.438 9.29329 121.438 11.2149V19.9414H117.324V12.0009C117.324 11.0226 117.084 10.2714 116.603 9.74735C116.123 9.2233 115.481 8.96187 114.677 8.96306C113.803 8.96306 113.096 9.25129 112.554 9.82777C112.013 10.4042 111.742 11.1729 111.742 12.1337V19.9414H107.628V12.0273C107.628 11.084 107.379 10.3368 106.88 9.78542C106.382 9.2341 105.732 8.95997 104.929 8.96306C104.072 8.96306 103.373 9.25129 102.832 9.82777C102.291 10.4042 102.02 11.1817 102.02 12.16V19.9414H97.9062V5.58249H101.916V7.62649C102.317 6.85786 102.92 6.25517 103.724 5.81842C104.528 5.38168 105.401 5.17196 106.344 5.18928C107.253 5.18928 108.074 5.3946 108.808 5.80525C109.542 6.2159 110.093 6.82298 110.459 7.62649C110.983 6.85786 111.699 6.25944 112.608 5.83123C113.516 5.40303 114.494 5.18845 115.542 5.1875Z" fill="#3D68FF"/>
                <path d="M68.5043 0.5H72.0923C72.3202 0.5 72.5459 0.544889 72.7564 0.632105C72.967 0.719321 73.1583 0.847155 73.3195 1.00831C73.4806 1.16946 73.6084 1.36078 73.6957 1.57134C73.7829 1.78189 73.8278 2.00757 73.8278 2.23548V2.50699C73.8278 2.73489 73.7829 2.96057 73.6957 3.17113C73.6084 3.38168 73.4806 3.573 73.3195 3.73415C73.1583 3.89531 72.967 4.02314 72.7564 4.11036C72.5459 4.19757 72.3202 4.24246 72.0923 4.24246H70.2394C69.7791 4.24246 69.3377 4.05962 69.0122 3.73415C68.6868 3.40869 68.5039 2.96726 68.5039 2.50699V0.5H68.5043Z" fill="#3D68FF"/>
                <path d="M90.0941 0.5H93.6821C94.1424 0.5 94.5838 0.682844 94.9093 1.00831C95.2348 1.33377 95.4176 1.7752 95.4176 2.23548V2.50699C95.4176 2.96726 95.2348 3.40869 94.9093 3.73415C94.5838 4.05962 94.1424 4.24246 93.6821 4.24246H91.8292C91.3689 4.24246 90.9275 4.05962 90.6021 3.73415C90.2766 3.40869 90.0938 2.96726 90.0938 2.50699V0.5H90.0941Z" fill="#3D68FF"/>
            </svg>

        </>
    );
};

export default LogoTitle;