import CircularProgress from "components/common/CircularProgress/CircularProgress";
import { REQUEST_STATUS } from "hooks";
import React, { FC, Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SaveButtonStyled } from "./Button.style";
import { SaveButtonType } from "./Button.types";

const Button: FC<SaveButtonType> = (props) => {
  const { t } = useTranslation();
  const {
    onClick,
    type,
    value = t("COMMON.SAVE"),
    disabled,
    className,
    symbol = false,
    status = REQUEST_STATUS.initial,
  } = props;

  const renderButtonContent = useMemo(() => {
    if (status === REQUEST_STATUS.loading) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <CircularProgress size={19} thickness={4} color="#fff" />
        </div>
      );
    } else {
      return (
        <Fragment>
          {symbol && <> &#43; </>}
          {value}
        </Fragment>
      );
    }
  }, [status]);

  return (
    <SaveButtonStyled
      className={className}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {renderButtonContent}
    </SaveButtonStyled>
  );
};

export default Button;
