import styled from "styled-components";
import { Table, TableBody, TableCell, TableContainer } from "@mui/material";

export const TableWrapperStyle = styled.div`
  position: relative;
  .normalize-table {
    width: 100%;
    height: 55px;
  }
`;
export const TableContainerStyle = styled(TableContainer)<any>`
  max-height: calc(100vh - 225px);
  min-height: calc(100vh - 225px);
  background-color: #fff;
`;

export const StyledTable = styled(Table)`
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.bg.whiteBg};
  border-collapse: inherit !important;
`;

export const TableCellStyled = styled(TableCell)`
  color: ${({ theme }) => theme.colors.textColor};
  white-space: nowrap;
`;

export const TableBodyStyled = styled(TableBody)<any>`
  row-gap: 0;
  border: none !important;
  overflow-y: auto;
  .columns {
    transition: all 0.3s;
    border-collapse: inherit !important;
    border-width: 0 !important;
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium:last-child {
      text-align: end;
    }
    .MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
      color: ${({ theme }) => theme.colors.text.lightBlack} !important ;
    }
  }
  .css-1ex1afd-MuiTableCell-root{
    white-space: nowrap !important;
  }
`;

export const TableCellCheckbox = styled(TableCell)`
  width: 50px !important;
`;
