import React from "react";

const Burger = (props: any) => {
  return (
    <svg
      width="24"
      height="29"
      viewBox="0 0 24 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 8.46939C3 7.83477 3.51446 7.32031 4.14907 7.32031H10.1436C10.7782 7.32031 11.2927 7.83477 11.2927 8.46939C11.2927 9.104 10.7782 9.61846 10.1436 9.61846H4.14907C3.51446 9.61846 3 9.104 3 8.46939ZM3 14.2148C3 13.5801 3.51446 13.0657 4.14907 13.0657H14.7209C15.3556 13.0657 15.87 13.5801 15.87 14.2148C15.87 14.8494 15.3556 15.3638 14.7209 15.3638H4.14907C3.51446 15.3638 3 14.8494 3 14.2148ZM3 19.9601C3 19.3255 3.51446 18.8111 4.14907 18.8111H19.8509C20.4855 18.8111 21 19.3255 21 19.9601C21 20.5947 20.4855 21.1092 19.8509 21.1092H4.14907C3.51446 21.1092 3 20.5947 3 19.9601Z"
        fill="#fff"
      />
    </svg>
  );
};

export default Burger;
