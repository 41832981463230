import React, { useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DEFAULT_LANGUAGE, language } from "constants/language/language";
import browserStorage from "services/storage/browserStorage";
import { LanguageStyled } from "./Language.style";
import { useTranslation } from "react-i18next";

const Language = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(
    browserStorage.get("i18nextLng") || DEFAULT_LANGUAGE
  );

  const languageChange = (event: SelectChangeEvent<unknown>): void => {
    event.target.value &&
      typeof event.target.value === "string" &&
      i18n.changeLanguage(event.target.value);
  };

  return (
    <LanguageStyled
      value={lang}
      onChange={languageChange}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
    >
      {language?.map((item) => {
        return (
          <MenuItem value={item.value} key={item.name}>
            {item.name}
          </MenuItem>
        );
      })}
    </LanguageStyled>
  );
};

export default Language;
