import styled from "styled-components";
import { INavbarStyleT } from "./Navbar.types";

export const NavbarStyle = styled.div<INavbarStyleT>`
  height: 70px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.03);
  background-color: #fff;
  transition: all 0.18s ease-in-out;
`;

export const UserStyled = styled.button`
  background: #edf1ff;
  border-radius: 35px;
  padding: 3.5px;
  transition: all 0.2s;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
  span {
    padding: 8.4px 5.5px;
    font-size: 16px;
    color: #000;
  }
  img {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    overflow: hidden;
  }
  &:hover {
    background: rgb(219, 229, 255);
  }
`;

export const LogOutButton = styled.button`
  border-radius: 50%;
  padding: 8px 10px;
  background: #edf1ff;
  transition: all 0.2s;
  box-shadow: none !important;
  border: none;
  cursor: pointer;
  svg {
    path {
      fill: #000;
    }
  }
  &:hover {
    background: rgb(219, 229, 255);
  }
`;
