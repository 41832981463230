import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { LogOutButton, NavbarStyle, UserStyled } from "./Navbar.styles";
import get from "lodash.get";
import UserIcon from "assets/icons/UserIcon";
import LogOut from "assets/icons/LogOut";
import toast from "react-hot-toast";
import { INavbarT } from "./Navbar.types";
import { Language } from "../components";

const Navbar = ({ collapse }: INavbarT) => {
  const { t, i18n } = useTranslation();
  const admin: any = localStorage.getItem("admin") || null;
  const user: any = JSON.parse(admin);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      localStorage.clear();
      navigate("/login");
    } catch (e) {
      toast.error("Error");
    }
  };

  return (
    <NavbarStyle
      collapse={collapse}
      className="d-flex align-items-center justify-content-between px-4 bg-white "
    >
      <span>Home</span>
      <div className="d-flex align-items-center">
        {/* <select
          onChange={(e: any) => {
            i18n.changeLanguage(e.value);
          }}
          className="mx-3"
          value={language.map((item: any) => {
            if (item.value === browserStorage.get("i18nextLng")) {
              return item;
            }
          })}
        >
          {language.map((lang: ILanguageT) => {
            return <option value={lang.value}>{lang.name}</option>;
          })}
        </select> */}

        <Language />

        <Link to="/home" className="text-decoration-none m-0 p-0">
          <UserStyled className="btn ml-auto mx-3 d-flex align-items-center justify-content-center">
            {/* {get(JSON.parse(user), "image") ? (
              <img
                src={
                  process.env.REACT_APP_PRODUCTION_URL +
                  get(JSON.parse(user), "image")
                }
                alt="user image"
              />
            ) : ( */}
            <UserIcon />
            {/* )} */}

            <span>
              {get(user, "firstName", "") + " " + get(user, "lastName", "")}
            </span>
          </UserStyled>
        </Link>

        <LogOutButton className={"btn ml-auto"} onClick={logout}>
          <LogOut />
        </LogOutButton>
      </div>
    </NavbarStyle>
  );
};

export default Navbar;
