import { ITheme } from "./theme.type";

export const theme: ITheme = {
  colors: {
    bg: {
      mainBg: "#F8F8F8",
      whiteBg: "#ffffff",
      blue: "#3d68ff",
      darkBlue: "#3d68ff",
      mainLightBg: "#F6F6F9",
    },
    text: {
      lightBlack: "#313949",
      black: "#000000",
      red: "red",
      blue: "#3d68ff",
      white: "#ffffff",
    },
    color: {
      blue: "#3d68ff",
      black: "#000000",
      lightBlack: "#333333",
      red: "red",
      lightGrey: "#EDF1FF",
    },
    border: {
      grey: "rgba(49, 57, 73, 0.1)",
      red: "red",
      lightBlack: "#ccc",
    },
    button: {
      blue: "#3d68ff",
      red: "red",
    },
  },
};
