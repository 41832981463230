import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import EditIcon from "assets/icons/EditIcon";
import { IEditButtonProps } from "./EditButton.types";
import { IconButtonStyled } from "./EditButton.style";
const EditButton: FC<IEditButtonProps> = ({ to }) => {
  return (
    <div onClick={(e: any) => e.stopPropagation()}>
      <Link to={to} className="text-decoration-none">
        <Tooltip title="Edit">
          <IconButtonStyled>
            <EditIcon />
          </IconButtonStyled>
        </Tooltip>
      </Link>
    </div>
  );
};

export default EditButton;
