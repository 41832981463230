import { TRequestStatus } from "interfaces/common";

export const REQUEST_STATUS: {
  [key: string]: TRequestStatus;
} = {
  initial: "INITIAL",
  success: "SUCCESS",
  failed: "FAILED",
  loading: "LOADING",
};
