import styled from "styled-components";
import { IInputContentStyled } from "./Input.types";

export const InputContentStyled = styled("div")<IInputContentStyled>`
  input {
    border-radius: 12px;
    border: 1px solid ${(props) => (props.isError ? "red" : "#f1f1f1")};
    width: 100%;
    color: #333;
    padding: 15px;
    outline: none;
    font-size: 16px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #f5f5f5;
    }
  }
`;
