import React from 'react';

const EduSystemIcon = () => {
    return (
        <>
            <svg width="50" height="80" viewBox="0 0 30 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.73516 7.80221L26.5856 -0.00342812C27.2624 1.97783 27.1244 4.14679 26.202 6.02631C25.2796 7.90583 23.6483 9.34195 21.6671 10.0187L6.287 15.2725C5.29637 15.6109 4.21189 15.5419 3.27213 15.0807C2.33237 14.6195 1.61431 13.8039 1.27592 12.8133V12.8133C0.937524 11.8227 1.00651 10.7382 1.46771 9.79842C1.92891 8.85866 2.74454 8.1406 3.73516 7.80221V7.80221Z" fill="#3D68FF"/>
                <path d="M3.78204 32.3803L26.6325 24.5747C26.9677 25.5557 27.1064 26.5932 27.0406 27.6279C26.9749 28.6625 26.706 29.6741 26.2493 30.6048C25.7926 31.5355 25.157 32.3671 24.3789 33.0522C23.6007 33.7373 22.6953 34.2624 21.7142 34.5975L6.33411 39.8513C5.84354 40.0189 5.32477 40.0882 4.80742 40.0553C4.29007 40.0223 3.78427 39.8878 3.31892 39.6594C2.85356 39.4309 2.43776 39.1131 2.09527 38.7239C1.75277 38.3348 1.49028 37.882 1.32279 37.3914V37.3914C0.984399 36.4008 1.05339 35.3163 1.51459 34.3765C1.97578 33.4368 2.79141 32.7187 3.78204 32.3803V32.3803Z" fill="#3D68FF"/>
                <path d="M17.6538 15.4436L3.49843 20.2791C1.66338 20.9059 0.683941 22.9017 1.31079 24.7367L1.59261 25.5617C2.21946 27.3968 4.21522 28.3762 6.05027 27.7494L20.2056 22.914C22.0407 22.2871 23.0201 20.2913 22.3933 18.4563L22.1114 17.6313C21.4846 15.7962 19.4888 14.8168 17.6538 15.4436Z" fill="#3D68FF"/>
            </svg>

        </>
    );
};

export default EduSystemIcon;