import Employee from "assets/icons/Employee";
import Plan from "assets/icons/Plan";
import { ISideItemsT } from "./Sidebar.types";
import NewsIcon from "assets/icons/NewsIcon";

export const SideItems: ISideItemsT[] = [
  {
    id: "users",
    title: "USER",
    path: "/users",
    icon: <Employee />,
    collapse: false,
    insidePaths: ["/users/add", "/users/details"],
  },
  {
    id: "plans",
    title: "PLAN",
    path: "/plans",
    icon: <Plan />,
    collapse: false,
    insidePaths: ["/plans/add", "/plans/edit"],
  },
  {
    id: "cashback",
    title: "CASHBACK",
    path: "/cashback",
    icon: <Plan />,
    collapse: false,
    insidePaths: ["/cashback/add", "/cashback/edit"],
  },
  {
    id: "version",
    title: "VERSION",
    path: "/version",
    icon: <Plan />,
    collapse: false,
    insidePaths: ["/version/edit"],
  },
  {
    id: "news",
    title: "NEWS",
    path: "/news",
    icon: <NewsIcon />,
    collapse: false,
    insidePaths: ["/version/edit"],
  },
  // {
  //   id: "users",
  //   title: "USER",
  //   path: "/users",
  //   icon: <Employee />,
  //   collapse: false,
  //   children: [
  //     {
  //       id: "user",
  //       title: "USER",
  //       path: "/users/create",
  //     },
  //     {
  //       id: "user",
  //       title: "USER",
  //       path: "/users/create",
  //     },
  //   ],
  // },
  // {
  //   id: "users",
  //   title: "USER",
  //   path: "/users",
  //   icon: <Employee />,
  //   collapse: false,
  // },
];
