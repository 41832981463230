import styled from "styled-components";

export const SidebarStyle = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.whiteBg} !important;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100% !important;
  /* width: 300px; */
  .sidebar-header {
    padding: 18px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    height: 70px;
    background: #3d68ff;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .burger {
      cursor: pointer;
      margin-left: 80px;
    }
    .rotate-burger {
      transform: rotateY(180deg);
      margin: 0 auto;
    }
  }
  .sidebar-footer {
    text-align: center;
  }
  .pro-sidebar-header {
    position: sticky;
    top: 0;
    z-index: 100000000000;
  }
  .pro-sidebar-content {
    position: relative !important;
    overflow-y: auto;
    background: #fff;
    transition: 0.2s all;
    &::-webkit-scrollbar {
      opacity: 0;
      position: absolute !important;
      z-index: 100000000 !important;
      right: 0 !important;
      width: 5px !important;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      transition: all 0.3s;
      background: #fff;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: #3d68ff;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px !important;
      &:hover {
        cursor: pointer !important;
      }
    }
  }
  & .pro-sidebar > .pro-sidebar-inner {
    background: white;
  }
  &
    .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    .pro-inner-item
    .pro-icon-wrapper {
    background: white;
  }
  &
    .pro-sidebar
    .pro-menu
    > ul
    > .pro-sub-menu
    > .pro-inner-list-item
    .pro-inner-item:hover {
    color: #3d68ff;
    background: transparent;
    //margin: 0;
  }
  & .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    padding-left: 3rem;
    background: white;
  }
  & .pro-sidebar .pro-menu .pro-menu-item {
    color: #000;
  }
  & .pro-sidebar .pro-menu .pro-menu-item.active {
    color: #3d68ff !important;
    background-color: transparent;
  }
  & .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: #3d68ff !important;
  }
  & .pro-sidebar .pro-menu.shaped .pro-menu-item .pro-inner-item {
    margin: 0 5px;
  }
  & .pro-sidebar .pro-menu.shaped .openMenu > .pro-inner-item {
    background: #edf1ff;
    color: #3d68ff;
    border-radius: 12px;
  }
  & .pro-sidebar .pro-menu.shaped .pro-menu-item .pro-inner-item:hover {
    background: #edf1ff;
    color: #3d68ff;
    border-radius: 12px;
    //padding-left:15px;
  }
  .pro-sidebar
    .pro-menu
    .pro-menu-item.pro-sub-menu
    > .pro-inner-item:hover
    > .pro-arrow-wrapper {
    //right: 15px !important;
    .pro-arrow {
      color: red;
    }
  }
  .pro-sidebar .pro-menu .pro-menu-item.focus {
    color: black;
  }

  // .pro-sidebar .pro-menu.shaped .pro-menu-item .pro-inner-item.open .pro-icon svg path {
  //   fill: #3D68FF;
  // }
  & svg {
    width: 25px !important;
    height: 25px !important;
  }

  & .logo {
    width: 125px !important;
    height: 40px;
  }
  & .pro-sidebar .pro-menu.shaped .openMenu .pro-inner-item .pro-icon svg path {
    fill: #3d68ff;
  }
  &
    .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    .pro-inner-item:hover
    .pro-icon
    svg
    path {
    fill: #3d68ff;
  }
  & .pro-sidebar .pro-menu.shaped .openMenu .pro-inner-item .pro-icon {
    background: #edf1ff;
    color: #3d68ff;
  }
  &
    .pro-sidebar
    .pro-menu.shaped
    .pro-menu-item
    .pro-inner-item:hover
    .pro-icon {
    background: #edf1ff;
    color: #3d68ff;
  }
  &
    .pro-sidebar.collapsed
    .pro-menu
    > ul
    > .pro-menu-item.pro-sub-menu
    > .pro-inner-list-item
    > .popper-inner {
    background: white;
  }
  .collapsed
    .pro-sidebar-inner
    .pro-sidebar-layout
    .pro-sidebar-content
    .pro-menu
    ul
    .pro-sub-menu
    .pro-inner-list-item
    .popper-inner {
    margin-left: 15px !important;
    z-index: 99;
  }
  .collapsed
    .pro-sidebar-inner
    .pro-sidebar-layout
    .pro-sidebar-content
    .pro-menu
    ul
    .pro-sub-menu
    .pro-inner-item {
    margin-left: 11px !important;
    padding-left: 13px !important;
  }
  .collapsed
    .pro-sidebar-inner
    .pro-sidebar-layout
    .pro-sidebar-content
    .pro-menu
    ul
    .pro-sub-menu:hover {
    .pro-inner-item {
      background: #edf1ff !important;
      border-radius: 12px;
      .pro-icon-wrapper {
        background: #edf1ff !important;
        .pro-icon {
          svg {
            path {
              fill: #3d68ff !important;
            }
          }
        }
      }
    }
    .pro-inner-list-item {
      .pro-inner-item {
        background: transparent !important;
        border-radius: 0 !important;
      }
    }
  }
`;

export const SidebarFooterLink = styled("a")`
  color: #adadad;
  text-decoration: none;
`;
