import { useRequest } from "hooks";

export const useCommon = () => {
  const [getRoleClient, getRoleData, getRoleStatus, getRoleError] =
    useRequest();

  const getRole = async (data: any) => {
    // await getRoleClient.get("employee/profile");
  };
  return {
    state: {
      createEbookState: {
        getRoleData,
        getRoleStatus,
        getRoleError,
      },
    },
    actions: {
      getRole,
    },
  };
};
