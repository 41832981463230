import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import get from "lodash.get";
import { Toaster } from "react-hot-toast";

import { routes } from "routes/constants/Routes.constants";
import { Navbar, Sidebar } from "components";
import { StyledApp } from "App.style";
import browserStorage from "services/storage/browserStorage";
import { hasToken } from "routes/utils/hasToken/hasToken";
import RoleManager from "services/roleManagement";
import { CommonContext } from "context";
import Loader from "pages/loader/container/Loader";

const Routes = () => {
  const [hasAccess] = RoleManager();
  return (
    <Switch>
      {routes.map((route, index) => (
        <React.Fragment key={index}>
          <Route {...route} />
          {/* {hasAccess(route.key) && <Route {...route} />} */}
        </React.Fragment>
      ))}
    </Switch>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  let browserStorageCollape: any = browserStorage.get("collapse");
  const [collapse, setCollapse] = useState<boolean>(false);
  const {
    state: {
      createEbookState: { getRoleData, getRoleStatus, getRoleError },
    },
    actions: { getRole },
  } = useContext(CommonContext);

  useEffect(() => {
    getRole();
    if (!hasToken()) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    browserStorage.set("role", get(getRoleData, "data.role"));
  }, [getRoleStatus]);

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <Sidebar collapse={collapse} setCollapse={setCollapse} />
        <StyledApp collapse={collapse}>
          <Navbar collapse={collapse} />
          <Suspense fallback={<Loader />}>
            <div style={{ width: "100%", height: "100%" }}>
              <Routes />
            </div>
          </Suspense>
        </StyledApp>
      </div>
    </>
  );
};

export default Dashboard;
