import styled from "styled-components";

interface IStyledAppT {
  collapse: boolean;
}

export const StyledApp = styled.div<IStyledAppT>`
  background-color: ${({ theme }) => theme.colors.bg.mainBg};
  width: 100%;
  padding-left: ${({ collapse }) => (!collapse ? "270px" : "80px")};
  /* min-height: 100vh; */
  transition: width, left, right, 0.3s;
  /* padding-left: ${(collapse) =>
    !collapse ? "max(20vw + 30px, 300px)" : "max(300px, 300px)"}; */
  /* padding-top: 100px;
  padding-right: 30px;
  padding-bottom: 30px; */
`;
